<template>
  <div>
      <back text="商业资讯"></back>
      <div class="mian">
          <h3>知识产权知识产权知识产权知识产权知识产权知识产权</h3>
          <p>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </p>
          <img src="" alt="">
          <p>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </p>
          <img src="" alt="">
          <p>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </p>
      </div>
  </div>
</template>

<script>
import back from '../../components/square/back.vue'
export default {
    name:'information',
    components: {
        back
    },
    data() {
        return {
            property: 'value',
        };
    },
}
</script>

<style lang="scss" scoped>
.mian{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    background-color: #FFFFFF;
    margin: auto;
    padding-bottom: 176px;
    h3{
        margin-top: 50px;
        margin-bottom: 73px;
    }
    p{
        width: 100%;
        word-wrap:break-word;
    }
    img{
        width: 100%;
        height: 400px;
        margin: 50px 0;
        background-color: #f8f8f8;
    }
}
</style>